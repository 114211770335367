export const REPORT_PERMISSION_CODE = {
    /*==================== Inbound Report ====================*/
    /*==================== Purchase Order ====================*/
    REP_IB_PURCHASE_REMAINING: {
        code: 'REPORT:PO:REMAIN',
        read: 'REPORT:PO:REMAIN:R',
        create: 'REPORT:PO:REMAIN:C',
        edit: 'REPORT:PO:REMAIN:U',
        delete: 'REPORT:PO:REMAIN:D',
        all: ['REPORT:PO:REMAIN:R', 'REPORT:PO:REMAIN:C', 'REPORT:PO:REMAIN:U', 'REPORT:PO:REMAIN:D']
    },
    REP_IB_PURCHASE_ORDER: {
        code: 'REPORT:PO:PO',
        read: 'REPORT:PO:PO:R',
        create: 'REPORT:PO:PO:C',
        edit: 'REPORT:PO:PO:U',
        delete: 'REPORT:PO:PO:D',
        all: ['REPORT:PO:PO:R', 'REPORT:PO:PO:C', 'REPORT:PO:PO:U', 'REPORT:PO:PO:D']
    },
    REP_IB_PURCHASE_DELIVERY: {
        code: 'REPORT:PO:DO',
        read: 'REPORT:PO:DO:R',
        create: 'REPORT:PO:DO:C',
        edit: 'REPORT:PO:DO:U',
        delete: 'REPORT:PO:DO:D',
        all: ['REPORT:PO:DO:R', 'REPORT:PO:DO:C', 'REPORT:PO:DO:U', 'REPORT:PO:DO:D']
    },
    REP_IB_CUST_PAYMENT: {
        code: 'REPORT:PO:INVOICE',
        read: 'REPORT:PO:INVOICE:R',
        create: 'REPORT:PO:INVOICE:C',
        edit: 'REPORT:PO:INVOICE:U',
        delete: 'REPORT:PO:INVOICE:D',
        all: ['REPORT:PO:INVOICE:R', 'REPORT:PO:INVOICE:C', 'REPORT:PO:INVOICE:U', 'REPORT:PO:INVOICE:D']
    },
    REP_IB_DEALER_PURCHASE: {
        code: 'REPORT:PO:DEALER',
        read: 'REPORT:PO:DEALER:R',
        create: 'REPORT:PO:DEALER:C',
        edit: 'REPORT:PO:DEALER:U',
        delete: 'REPORT:PO:DEALER:D',
        all: ['REPORT:PO:DEALER:R', 'REPORT:PO:DEALER:C', 'REPORT:PO:DEALER:U', 'REPORT:PO:DEALER:D']
    },
    /*==================== Ring Pull ====================*/
    REP_IB_RING_REMAINING: {
        code: 'REPORT:RP:REMAIN',
        read: 'REPORT:RP:REMAIN:R',
        create: 'REPORT:RP:REMAIN:C',
        edit: 'REPORT:RP:REMAIN:U',
        delete: 'REPORT:RP:REMAIN:D',
        all: ['REPORT:RP:REMAIN:R', 'REPORT:RP:REMAIN:C', 'REPORT:RP:REMAIN:U', 'REPORT:RP:REMAIN:D']
    },
    REP_IB_RING_PULL: {
        code: 'REPORT:RP:RP',
        read: 'REPORT:RP:RP:R',
        create: 'REPORT:RP:RP:C',
        edit: 'REPORT:RP:RP:U',
        delete: 'REPORT:RP:RP:D',
        all: ['REPORT:RP:RP:R', 'REPORT:RP:RP:C', 'REPORT:RP:RP:U', 'REPORT:RP:RP:D']
    },
    REP_IB_RING_DELIVERY: {
        code: 'REPORT:RP:DO',
        read: 'REPORT:RP:DO:R',
        create: 'REPORT:RP:DO:C',
        edit: 'REPORT:RP:DO:U',
        delete: 'REPORT:RP:DO:D',
        all: ['REPORT:RP:DO:R', 'REPORT:RP:DO:C', 'REPORT:RP:DO:U', 'REPORT:RP:DO:D']
    },
    /*==================== Gratis ====================*/
    REP_IB_GRATIS_REMAINING: {
        code: 'REPORT:GT:REMAIN',
        read: 'REPORT:GT:REMAIN:R',
        create: 'REPORT:GT:REMAIN:C',
        edit: 'REPORT:GT:REMAIN:U',
        delete: 'REPORT:GT:REMAIN:D',
        all: ['REPORT:GT:REMAIN:R', 'REPORT:GT:REMAIN:C', 'REPORT:GT:REMAIN:U', 'REPORT:GT:REMAIN:D']
    },
    REP_IB_GRATIS_CLAIM: {
        code: 'REPORT:GT:GT',
        read: 'REPORT:GT:GT:R',
        create: 'REPORT:GT:GT:C',
        edit: 'REPORT:GT:GT:U',
        delete: 'REPORT:GT:GT:D',
        all: ['REPORT:GT:GT:R', 'REPORT:GT:GT:C', 'REPORT:GT:GT:U', 'REPORT:GT:GT:D']
    },
    REP_IB_GRATIS_DELIVERY: {
        code: 'REPORT:GT:DO',
        read: 'REPORT:GT:DO:R',
        create: 'REPORT:GT:DO:C',
        edit: 'REPORT:GT:DO:U',
        delete: 'REPORT:GT:DO:D',
        all: ['REPORT:GT:DO:R', 'REPORT:GT:DO:C', 'REPORT:GT:DO:U', 'REPORT:GT:DO:D']
    },
    /*==================== Sales Report ====================*/
    REP_IB_SALES_REPORT: {
        code: 'REPORT:SR:SO',
        read: 'REPORT:SR:SO:R',
        create: 'REPORT:SR:SO:C',
        edit: 'REPORT:SR:SO:U',
        delete: 'REPORT:SR:SO:D',
        all: ['REPORT:SR:SO:R', 'REPORT:SR:SO:C', 'REPORT:SR:SO:U', 'REPORT:SR:SO:D']
    },
    REP_IB_SALES_SUMMARY: {
        code: 'REPORT:SR:SUMMARY',
        read: 'REPORT:SR:SUMMARY:R',
        create: 'REPORT:SR:SUMMARY:C',
        edit: 'REPORT:SR:SUMMARY:U',
        delete: 'REPORT:SR:SUMMARY:D',
        all: ['REPORT:SR:SUMMARY:R', 'REPORT:SR:SUMMARY:C', 'REPORT:SR:SUMMARY:U', 'REPORT:SR:SUMMARY:D']
    },
    /*==================== Return ====================*/
    REP_IB_RETURN_PO: {
        code: 'REPORT:RT:PO',
        read: 'REPORT:RT:PO:R',
        create: 'REPORT:RT:PO:C',
        edit: 'REPORT:RT:PO:U',
        delete: 'REPORT:RT:PO:D',
        all: ['REPORT:RT:PO:R', 'REPORT:RT:PO:C', 'REPORT:RT:PO:U', 'REPORT:RT:PO:D']
    },
    REP_IB_RETURN_DO: {
        code: 'REPORT:RT:DO',
        read: 'REPORT:RT:DO:R',
        create: 'REPORT:RT:DO:C',
        edit: 'REPORT:RT:DO:U',
        delete: 'REPORT:RT:DO:D',
        all: ['REPORT:RT:DO:R', 'REPORT:RT:DO:C', 'REPORT:RT:DO:U', 'REPORT:RT:DO:D']
    },
    /*==================== Outbound Report ====================*/
    /*==================== Sales Report ====================*/
    REP_OB_DAILY_SHIP_PLAN: {
        code: 'REPORT:SR:SHIP',
        read: 'REPORT:SR:SHIP:R',
        create: 'REPORT:SR:SHIP:C',
        edit: 'REPORT:SR:SHIP:U',
        delete: 'REPORT:SR:SHIP:D',
        all: ['REPORT:SR:SHIP:R', 'REPORT:SR:SHIP:C', 'REPORT:SR:SHIP:U', 'REPORT:SR:SHIP:D']
    },
    REP_OB_SALE_DAILY_STATUS: {
        code: 'REPORT:SR:DSO',
        read: 'REPORT:SR:DSO:R',
        create: 'REPORT:SR:DSO:C',
        edit: 'REPORT:SR:DSO:U',
        delete: 'REPORT:SR:DSO:D',
        all: ['REPORT:SR:DSO:R', 'REPORT:SR:DSO:C', 'REPORT:SR:DSO:U', 'REPORT:SR:DSO:D']
    },
    REP_OB_SALE_DAILY_STATUS_DETAIL: {
        code: 'REPORT:SR:DSOD',
        read: 'REPORT:SR:DSOD:R',
        create: 'REPORT:SR:DSOD:C',
        edit: 'REPORT:SR:DSOD:U',
        delete: 'REPORT:SR:DSOD:D',
        all: ['REPORT:SR:DSOD:R', 'REPORT:SR:DSOD:C', 'REPORT:SR:DSOD:U', 'REPORT:SR:DSOD:D']
    },
    REP_OB_SALE_TRANSACTION: {
        code: 'REPORT:SR:SOT',
        read: 'REPORT:SR:SOT:R',
        create: 'REPORT:SR:SOT:C',
        edit: 'REPORT:SR:SOT:U',
        delete: 'REPORT:SR:SOT:D',
        all: ['REPORT:SR:SOT:R', 'REPORT:SR:SOT:C', 'REPORT:SR:SOT:U', 'REPORT:SR:SOT:D']
    },
    REP_OB_SALE_SUMMARY: {
        code: 'REPORT:SR:SOS',
        read: 'REPORT:SR:SOS:R',
        create: 'REPORT:SR:SOS:C',
        edit: 'REPORT:SR:SOS:U',
        delete: 'REPORT:SR:SOS:D',
        all: ['REPORT:SR:SOS:R', 'REPORT:SR:SOS:C', 'REPORT:SR:SOS:U', 'REPORT:SR:SOS:D']
    },
    REP_OB_PROD_RETURN: {
        code: 'REPORT:SR:RTO',
        read: 'REPORT:SR:RTO:R',
        create: 'REPORT:SR:RTO:C',
        edit: 'REPORT:SR:RTO:U',
        delete: 'REPORT:SR:RTO:D',
        all: ['REPORT:SR:RTO:R', 'REPORT:SR:RTO:C', 'REPORT:SR:RTO:U', 'REPORT:SR:RTO:D']
    },
    REP_OB_PROD_ROTATION: {
        code: 'REPORT:SR:ROT',
        read: 'REPORT:SR:ROT:R',
        create: 'REPORT:SR:ROT:C',
        edit: 'REPORT:SR:ROT:U',
        delete: 'REPORT:SR:ROT:D',
        all: ['REPORT:SR:ROT:R', 'REPORT:SR:ROT:C', 'REPORT:SR:ROT:U', 'REPORT:SR:ROT:D']
    },
    REP_OB_SO_REMAINING: {
        code: 'REPORT:SR:SOR',
        read: 'REPORT:SR:SOR:R',
        create: 'REPORT:SR:SOR:C',
        edit: 'REPORT:SR:SOR:U',
        delete: 'REPORT:SR:SOR:D',
        all: ['REPORT:SR:SOR:R', 'REPORT:SR:SOR:C', 'REPORT:SR:SOR:U', 'REPORT:SR:SOR:D']
    },
    /*==================== KPI and Performance ====================*/
    REP_OB_CALL_COMPLIANCE: {
        code: 'REPORT:SALE:CALL',
        read: 'REPORT:SALE:CALL:R',
        create: 'REPORT:SALE:CALL:C',
        edit: 'REPORT:SALE:CALL:U',
        delete: 'REPORT:SALE:CALL:D',
        all: ['REPORT:SALE:CALL:R', 'REPORT:SALE:CALL:C', 'REPORT:SALE:CALL:U', 'REPORT:SALE:CALL:D']
    },
    REP_OB_SS_CALL_COMPLIANCE: {
        code: 'REPORT:SALE:SSCALL',
        read: 'REPORT:SALE:SSCALL:R',
        create: 'REPORT:SALE:SSCALL:C',
        edit: 'REPORT:SALE:SSCALL:U',
        delete: 'REPORT:SALE:SSCALL:D',
        all: ['REPORT:SALE:SSCALL:R', 'REPORT:SALE:SSCALL:C', 'REPORT:SALE:SSCALL:U', 'REPORT:SALE:SSCALL:D']
    },
    REP_OB_OUTLET_PERF_SUMMARY: {
        code: 'REPORT:SALE:ORDER',
        read: 'REPORT:SALE:ORDER:R',
        create: 'REPORT:SALE:ORDER:C',
        edit: 'REPORT:SALE:ORDER:U',
        delete: 'REPORT:SALE:ORDER:D',
        all: ['REPORT:SALE:ORDER:R', 'REPORT:SALE:ORDER:C', 'REPORT:SALE:ORDER:U', 'REPORT:SALE:ORDER:D']
    },
    REP_OB_OUTLET_PERF_SUMMARY_DO: {
        code: 'REPORT:PERF:DO',
        read: 'REPORT:PERF:DO:R',
        create: 'REPORT:PERF:DO:C',
        edit: 'REPORT:PERF:DO:U',
        delete: 'REPORT:PERF:DO:D',
        all: ['REPORT:PERF:DO:R', 'REPORT:PERF:DO:C', 'REPORT:PERF:DO:U', 'REPORT:PERF:DO:D']
    },
    REP_OB_OUTLET_PERF_SUMMARY_DO_NEW: {
        code: 'REPORT:PERFNEW:DONEW',
        read: 'REPORT:PERFNEW:DONEW:R',
        create: 'REPORT:PERFNEW:DONEW:C',
        edit: 'REPORT:PERFNEW:DONEW:U',
        delete: 'REPORT:PERFNEW:DONEW:D',
        all: ['REPORT:PERFNEW:DONEW:R', 'REPORT:PERFNEW:DONEW:C', 'REPORT:PERFNEW:DONEW:U', 'REPORT:PERFNEW:DONEW:D']
    },
    REP_OB_MONTHLY_SALE_DEALER_CATEGORY: {
        code: 'REPORT:SALE:ORDERDC',
        read: 'REPORT:SALE:ORDERDC:R',
        create: 'REPORT:SALE:ORDERDC:C',
        edit: 'REPORT:SALE:ORDERDC:U',
        delete: 'REPORT:SALE:ORDERDC:D',
        all: ['REPORT:SALE:ORDERDC:R', 'REPORT:SALE:ORDERDC:C', 'REPORT:SALE:ORDERDC:U', 'REPORT:SALE:ORDERDC:D']
    },
    REP_OB_MONTHLY_SALE_DEALER_PROD: {
        code: 'REPORT:SALE:ORDERDP',
        read: 'REPORT:SALE:ORDERDP:R',
        create: 'REPORT:SALE:ORDERDP:C',
        edit: 'REPORT:SALE:ORDERDP:U',
        delete: 'REPORT:SALE:ORDERDP:D',
        all: ['REPORT:SALE:ORDERDP:R', 'REPORT:SALE:ORDERDP:C', 'REPORT:SALE:ORDERDP:U', 'REPORT:SALE:ORDERDP:D']
    },
    REP_OB_PROD_AVAIL_OUTLET: {
        code: 'REPORT:SALE:EXPIRED',
        read: 'REPORT:SALE:EXPIRED:R',
        create: 'REPORT:SALE:EXPIRED:C',
        edit: 'REPORT:SALE:EXPIRED:U',
        delete: 'REPORT:SALE:EXPIRED:D',
        all: ['REPORT:SALE:EXPIRED:R', 'REPORT:SALE:EXPIRED:C', 'REPORT:SALE:EXPIRED:U', 'REPORT:SALE:EXPIRED:D']
    },
    REP_OB_PROD_AVAIL_SUMMARY: {
        code: 'REPORT:SALE:STOCK',
        read: 'REPORT:SALE:STOCK:R',
        create: 'REPORT:SALE:STOCK:C',
        edit: 'REPORT:SALE:STOCK:U',
        delete: 'REPORT:SALE:STOCK:D',
        all: ['REPORT:SALE:STOCK:R', 'REPORT:SALE:STOCK:C', 'REPORT:SALE:STOCK:U', 'REPORT:SALE:STOCK:D']
    },
    REP_OB_NEW_OUTLET: {
        code: 'REPORT:SALE:OUTLET',
        read: 'REPORT:SALE:OUTLET:R',
        create: 'REPORT:SALE:OUTLET:C',
        edit: 'REPORT:SALE:OUTLET:U',
        delete: 'REPORT:SALE:OUTLET:D',
        all: ['REPORT:SALE:OUTLET:R', 'REPORT:SALE:OUTLET:C', 'REPORT:SALE:OUTLET:U', 'REPORT:SALE:OUTLET:D']
    },
    REP_OB_SURVEY_TRANSACTION: {
        code: 'REPORT:SALE:SURVEY',
        read: 'REPORT:SALE:SURVEY:R',
        create: 'REPORT:SALE:SURVEY:C',
        edit: 'REPORT:SALE:SURVEY:U',
        delete: 'REPORT:SALE:SURVEY:D',
        all: ['REPORT:SALE:SURVEY:R', 'REPORT:SALE:SURVEY:C', 'REPORT:SALE:SURVEY:U', 'REPORT:SALE:SURVEY:D']
    },
    REP_OB_OUTLET_VISIT_SUMMARY: {
        code: 'REPORT:SALE:VISIT',
        read: 'REPORT:SALE:VISIT:R',
        create: 'REPORT:SALE:VISIT:C',
        edit: 'REPORT:SALE:VISIT:U',
        delete: 'REPORT:SALE:VISIT:D',
        all: ['REPORT:SALE:VISIT:R', 'REPORT:SALE:VISIT:C', 'REPORT:SALE:VISIT:U', 'REPORT:SALE:VISIT:D']
    },
    REP_OB_SALE_PERF_SUMMARY: {
        code: 'REPORT:SALE:SUMMARY',
        read: 'REPORT:SALE:SUMMARY:R',
        create: 'REPORT:SALE:SUMMARY:C',
        edit: 'REPORT:SALE:SUMMARY:U',
        delete: 'REPORT:SALE:SUMMARY:D',
        all: ['REPORT:SALE:SUMMARY:R', 'REPORT:SALE:SUMMARY:C', 'REPORT:SALE:SUMMARY:U', 'REPORT:SALE:SUMMARY:D']
    },
    REP_OB_PARTNER_INCENTIVE: {
        code: 'REPORT:SALE:PARTNER',
        read: 'REPORT:SALE:PARTNER:R',
        create: 'REPORT:SALE:PARTNER:C',
        edit: 'REPORT:SALE:PARTNER:U',
        delete: 'REPORT:SALE:PARTNER:D',
        all: ['REPORT:SALE:PARTNER:R', 'REPORT:SALE:PARTNER:C', 'REPORT:SALE:PARTNER:U', 'REPORT:SALE:PARTNER:D']
    },
    REP_OB_PROMOTION: {
        code: 'REPORT:SALE:PROMOTION',
        read: 'REPORT:SALE:PROMOTION:R',
        create: 'REPORT:SALE:PROMOTION:C',
        edit: 'REPORT:SALE:PROMOTION:U',
        delete: 'REPORT:SALE:PROMOTION:D',
        all: ['REPORT:SALE:PROMOTION:R', 'REPORT:SALE:PROMOTION:C', 'REPORT:SALE:PROMOTION:U', 'REPORT:SALE:PROMOTION:D']
    },
    REP_OB_OUTLET_AGREEMENT: {
        code: 'REPORT:SALE:AGREEMENT',
        read: 'REPORT:SALE:AGREEMENT:R',
        create: 'REPORT:SALE:AGREEMENT:C',
        edit: 'REPORT:SALE:AGREEMENT:U',
        delete: 'REPORT:SALE:AGREEMENT:D',
        all: ['REPORT:SALE:AGREEMENT:R', 'REPORT:SALE:AGREEMENT:C', 'REPORT:SALE:AGREEMENT:U', 'REPORT:SALE:AGREEMENT:D']
    },
    REP_OB_WAP_AGREEMENT: {
        code: 'REPORT:SALE:WAP',
        read: 'REPORT:SALE:WAP:R',
        create: 'REPORT:SALE:WAP:C',
        edit: 'REPORT:SALE:WAP:U',
        delete: 'REPORT:SALE:WAP:D',
        all: ['REPORT:SALE:WAP:R', 'REPORT:SALE:WAP:C', 'REPORT:SALE:WAP:U', 'REPORT:SALE:WAP:D']
    },
    /*==================== AR / AP ====================*/
    REP_OB_CUST_PAYMENT: {
        code: 'REPORT:INV:SPAYMENT',
        read: 'REPORT:INV:SPAYMENT:R',
        create: 'REPORT:INV:SPAYMENT:C',
        edit: 'REPORT:INV:SPAYMENT:U',
        delete: 'REPORT:INV:SPAYMENT:D',
        all: ['REPORT:INV:SPAYMENT:R', 'REPORT:INV:SPAYMENT:C', 'REPORT:INV:SPAYMENT:U', 'REPORT:INV:SPAYMENT:D']
    },
    REP_OB_DAILY_SALE_INV_PAY: {
        code: 'REPORT:INV:DPAYMENT',
        read: 'REPORT:INV:DPAYMENT:R',
        create: 'REPORT:INV:DPAYMENT:C',
        edit: 'REPORT:INV:DPAYMENT:U',
        delete: 'REPORT:INV:DPAYMENT:D',
        all: ['REPORT:INV:DPAYMENT:R', 'REPORT:INV:DPAYMENT:C', 'REPORT:INV:DPAYMENT:U', 'REPORT:INV:DPAYMENT:D']
    },
    REP_OB_DETAIL_INV_INFO: {
        code: 'REPORT:INV:PAYMENT',
        read: 'REPORT:INV:PAYMENT:R',
        create: 'REPORT:INV:PAYMENT:C',
        edit: 'REPORT:INV:PAYMENT:U',
        delete: 'REPORT:INV:PAYMENT:D',
        all: ['REPORT:INV:PAYMENT:R', 'REPORT:INV:PAYMENT:C', 'REPORT:INV:PAYMENT:U', 'REPORT:INV:PAYMENT:D']
    },
    /*==================== Trading Marketing ====================*/
    REP_OB_ASSET_DETAIL_OUTLET: {
        code: 'REPORT:TM:TAO',
        read: 'REPORT:TM:TAO:R',
        create: 'REPORT:TM:TAO:C',
        edit: 'REPORT:TM:TAO:U',
        delete: 'REPORT:TM:TAO:D',
        all: ['REPORT:TM:TAO:R', 'REPORT:TM:TAO:C', 'REPORT:TM:TAO:U', 'REPORT:TM:TAO:D']
    },
    REP_OB_ASSET_DETAIL_DEALER: {
        code: 'REPORT:TM:TAD',
        read: 'REPORT:TM:TAD:R',
        create: 'REPORT:TM:TAD:C',
        edit: 'REPORT:TM:TAD:U',
        delete: 'REPORT:TM:TAD:D',
        all: ['REPORT:TM:TAD:R', 'REPORT:TM:TAD:C', 'REPORT:TM:TAD:U', 'REPORT:TM:TAD:D']
    },
    REP_OB_MARKET_PROMO_DETAIL: {
        code: 'REPORT:TM:DPRO',
        read: 'REPORT:TM:DPRO:R',
        create: 'REPORT:TM:DPRO:C',
        edit: 'REPORT:TM:DPRO:U',
        delete: 'REPORT:TM:DPRO:D',
        all: ['REPORT:TM:DPRO:R', 'REPORT:TM:DPRO:C', 'REPORT:TM:DPRO:U', 'REPORT:TM:DPRO:D']
    },
    REP_OB_MARKET_PROMO_SUMMARY: {
        code: 'REPORT:TM:SPRO',
        read: 'REPORT:TM:SPRO:R',
        create: 'REPORT:TM:SPRO:C',
        edit: 'REPORT:TM:SPRO:U',
        delete: 'REPORT:TM:SPRO:D',
        all: ['REPORT:TM:SPRO:R', 'REPORT:TM:SPRO:C', 'REPORT:TM:SPRO:U', 'REPORT:TM:SPRO:D']
    },
    REP_OB_OUTLET_PROMO_TRANSACTION: {
        code: 'REPORT:TM:OPRO',
        read: 'REPORT:TM:OPRO:R',
        create: 'REPORT:TM:OPRO:C',
        edit: 'REPORT:TM:OPRO:U',
        delete: 'REPORT:TM:OPRO:D',
        all: ['REPORT:TM:OPRO:R', 'REPORT:TM:OPRO:C', 'REPORT:TM:OPRO:U', 'REPORT:TM:OPRO:D']
    },
    REP_OB_FRIDGE_DEPLOY_RETURN: {
        code: 'REPORT:TM:ASSETO',
        read: 'REPORT:TM:ASSETO:R',
        create: 'REPORT:TM:ASSETO:C',
        edit: 'REPORT:TM:ASSETO:U',
        delete: 'REPORT:TM:ASSETO:D',
        all: ['REPORT:TM:ASSETO:R', 'REPORT:TM:ASSETO:C', 'REPORT:TM:ASSETO:U', 'REPORT:TM:ASSETO:D']
    },
    REP_OB_SIGN_REQUEST_DEPLOY: {
        code: 'REPORT:TM:REQUEST',
        read: 'REPORT:TM:REQUEST:R',
        create: 'REPORT:TM:REQUEST:C',
        edit: 'REPORT:TM:REQUEST:U',
        delete: 'REPORT:TM:REQUEST:D',
        all: ['REPORT:TM:REQUEST:R', 'REPORT:TM:REQUEST:C', 'REPORT:TM:REQUEST:U', 'REPORT:TM:REQUEST:D']
    },
    REP_OB_TAS_MASTER: {
        code: 'REPORT:TAS:MASTER',
        read: 'REPORT:TAS:MASTER:R',
        create: 'REPORT:TAS:MASTER:C',
        edit: 'REPORT:TAS:MASTER:U',
        delete: 'REPORT:TAS:MASTER:D',
        all: ['REPORT:TAS:MASTER:R', 'REPORT:TAS:MASTER:C', 'REPORT:TAS:MASTER:U', 'REPORT:TAS:MASTER:D']
    },
    REP_OB_TAS_DEPLOY: {
        code: 'REPORT:TAS:DEPLOY',
        read: 'REPORT:TAS:DEPLOY:R',
        create: 'REPORT:TAS:DEPLOY:C',
        edit: 'REPORT:TAS:DEPLOY:U',
        delete: 'REPORT:TAS:DEPLOY:D',
        all: ['REPORT:TAS:DEPLOY:R', 'REPORT:TAS:DEPLOY:C', 'REPORT:TAS:DEPLOY:U', 'REPORT:TAS:DEPLOY:D']
    },
    REP_OB_TAS_MOVEMENT: {
        code: 'REPORT:TAS:MOVEMENT',
        read: 'REPORT:TAS:MOVEMENT:R',
        create: 'REPORT:TAS:MOVEMENT:C',
        edit: 'REPORT:TAS:MOVEMENT:U',
        delete: 'REPORT:TAS:MOVEMENT:D',
        all: ['REPORT:TAS:MOVEMENT:R', 'REPORT:TAS:MOVEMENT:C', 'REPORT:TAS:MOVEMENT:U', 'REPORT:TAS:MOVEMENT:D']
    },
    REP_OB_TAS_RETURN: {
        code: 'REPORT:TAS:RETURN',
        read: 'REPORT:TAS:RETURN:R',
        create: 'REPORT:TAS:RETURN:C',
        edit: 'REPORT:TAS:RETURN:U',
        delete: 'REPORT:TAS:RETURN:D',
        all: ['REPORT:TAS:RETURN:R', 'REPORT:TAS:RETURN:C', 'REPORT:TAS:RETURN:U', 'REPORT:TAS:RETURN:D']
    },
    /*==================== Touch Point ====================*/
    REP_OB_POSM_REQUEST: {
        code: 'REPORT:TP:REQUEST',
        read: 'REPORT:TP:REQUEST:R',
        create: 'REPORT:TP:REQUEST:C',
        edit: 'REPORT:TP:REQUEST:U',
        delete: 'REPORT:TP:REQUEST:D',
        all: ['REPORT:TP:REQUEST:R', 'REPORT:TP:REQUEST:C', 'REPORT:TP:REQUEST:U', 'REPORT:TP:REQUEST:D']
    },
    REP_OB_POSM_DEPLOY: {
        code: 'REPORT:TP:DEPLOY',
        read: 'REPORT:TP:DEPLOY:R',
        create: 'REPORT:TP:DEPLOY:C',
        edit: 'REPORT:TP:DEPLOY:U',
        delete: 'REPORT:TP:DEPLOY:D',
        all: ['REPORT:TP:DEPLOY:R', 'REPORT:TP:DEPLOY:C', 'REPORT:TP:DEPLOY:U', 'REPORT:TP:DEPLOY:D']
    },
    REP_OB_POSM_MOVEMENT: {
        code: 'REPORT:TP:MOV',
        read: 'REPORT:TP:MOV:R',
        create: 'REPORT:TP:MOV:C',
        edit: 'REPORT:TP:MOV:U',
        delete: 'REPORT:TP:MOV:D',
        all: ['REPORT:TP:MOV:R', 'REPORT:TP:MOV:C', 'REPORT:TP:MOV:U', 'REPORT:TP:MOV:D']
    },
    /*==================== Inventory ====================*/
    REP_OB_INVENTORY_MOVE_SUMMARY: {
        code: 'REPORT:INV:INV',
        read: 'REPORT:INV:INV:R',
        create: 'REPORT:INV:INV:C',
        edit: 'REPORT:INV:INV:U',
        delete: 'REPORT:INV:INV:D',
        all: ['REPORT:INV:INV:R', 'REPORT:INV:INV:C', 'REPORT:INV:INV:U', 'REPORT:INV:INV:D']
    },
    REP_OB_STOCK_MOVE_DETAIL: {
        code: 'REPORT:INV:STOCK',
        read: 'REPORT:INV:STOCK:R',
        create: 'REPORT:INV:STOCK:C',
        edit: 'REPORT:INV:STOCK:U',
        delete: 'REPORT:INV:STOCK:D',
        all: ['REPORT:INV:STOCK:R', 'REPORT:INV:STOCK:C', 'REPORT:INV:STOCK:U', 'REPORT:INV:STOCK:D']
    },
    REP_OB_INV_MOVE_DETAIL: {
        code: 'REPORT:INV:MOV',
        read: 'REPORT:INV:MOV:R',
        create: 'REPORT:INV:MOV:C',
        edit: 'REPORT:INV:MOV:U',
        delete: 'REPORT:INV:MOV:D',
        all: ['REPORT:INV:MOV:R', 'REPORT:INV:MOV:C', 'REPORT:INV:MOV:U', 'REPORT:INV:MOV:D']
    },
    REP_OB_DAILY_INV_MOVE_DETAIL: {
        code: 'REPORT:INV:DETAIL',
        read: 'REPORT:INV:DETAIL:R',
        create: 'REPORT:INV:DETAIL:C',
        edit: 'REPORT:INV:DETAIL:U',
        delete: 'REPORT:INV:DETAIL:D',
        all: ['REPORT:INV:DETAIL:R', 'REPORT:INV:DETAIL:C', 'REPORT:INV:DETAIL:U', 'REPORT:INV:DETAIL:D']
    },
    REP_OB_INV_MOVE_ADJ: {
        code: 'REPORT:INV:MA',
        read: 'REPORT:INV:MA:R',
        create: 'REPORT:INV:MA:C',
        edit: 'REPORT:INV:MA:U',
        delete: 'REPORT:INV:MA:D',
        all: ['REPORT:INV:MA:R', 'REPORT:INV:MA:C', 'REPORT:INV:MA:U', 'REPORT:INV:MA:D']
    },
    REP_OB_INV_MOVE_ADJ_DAILY: {
        code: 'REPORT:INV:MADAILY',
        read: 'REPORT:INV:MADAILY:R',
        create: 'REPORT:INV:MADAILY:C',
        edit: 'REPORT:INV:MADAILY:U',
        delete: 'REPORT:INV:MADAILY:D',
        all: ['REPORT:INV:MADAILY:R', 'REPORT:INV:MADAILY:C', 'REPORT:INV:MADAILY:U', 'REPORT:INV:MADAILY:D']
    },
    REP_OB_INV_SALE_MOVE: {
        code: 'REPORT:INV:ISVM',
        read: 'REPORT:INV:ISVM:R',
        create: 'REPORT:INV:ISVM:C',
        edit: 'REPORT:INV:ISVM:U',
        delete: 'REPORT:INV:ISVM:D',
        all: ['REPORT:INV:ISVM:R', 'REPORT:INV:ISVM:C', 'REPORT:INV:ISVM:U', 'REPORT:INV:ISVM:D']
    },
    REP_OB_INV_SALE_MOVE_DAILY: {
        code: 'REPORT:INV:ISVMDAILY',
        read: 'REPORT:INV:ISVMDAILY:R',
        create: 'REPORT:INV:ISVMDAILY:C',
        edit: 'REPORT:INV:ISVMDAILY:U',
        delete: 'REPORT:INV:ISVMDAILY:D',
        all: ['REPORT:INV:ISVMDAILY:R', 'REPORT:INV:ISVMDAILY:C', 'REPORT:INV:ISVMDAILY:U', 'REPORT:INV:ISVMDAILY:D']
    },
    REP_OB_INV_ON_HAND: {
        code: 'REPORT:INV:OH',
        read: 'REPORT:INV:OH:R',
        create: 'REPORT:INV:OH:C',
        edit: 'REPORT:INV:OH:U',
        delete: 'REPORT:INV:OH:D',
        all: ['REPORT:INV:OH:R', 'REPORT:INV:OH:C', 'REPORT:INV:OH:U', 'REPORT:INV:OH:D']
    },
    REP_OB_SAFE_STOCK: {
        code: 'REPORT:INV:SAFE',
        read: 'REPORT:INV:SAFE:R',
        create: 'REPORT:INV:SAFE:C',
        edit: 'REPORT:INV:SAFE:U',
        delete: 'REPORT:INV:SAFE:D',
        all: ['REPORT:INV:SAFE:R', 'REPORT:INV:SAFE:C', 'REPORT:INV:SAFE:U', 'REPORT:INV:SAFE:D']
    },
    REP_OB_STOCK_RECONCILE: {
        code: 'REPORT:INV:RECONCILE',
        read: 'REPORT:INV:RECONCILE:R',
        create: 'REPORT:INV:RECONCILE:C',
        edit: 'REPORT:INV:RECONCILE:U',
        delete: 'REPORT:INV:RECONCILE:D',
        all: ['REPORT:INV:RECONCILE:R', 'REPORT:INV:RECONCILE:C', 'REPORT:INV:RECONCILE:U', 'REPORT:INV:RECONCILE:D']
    },
    /*==================== Ring Pull Claim ====================*/
    REP_OB_RING_TRANSACTION: {
        code: 'REPORT:RP:TRA',
        read: 'REPORT:RP:TRA:R',
        create: 'REPORT:RP:TRA:C',
        edit: 'REPORT:RP:TRA:U',
        delete: 'REPORT:RP:TRA:D',
        all: ['REPORT:RP:TRA:R', 'REPORT:RP:TRA:C', 'REPORT:RP:TRA:U', 'REPORT:RP:TRA:D']
    },
    REP_OB_RING_CLAIM_CUST_DAILY: {
        code: 'REPORT:RP:DTRAO',
        read: 'REPORT:RP:DTRAO:R',
        create: 'REPORT:RP:DTRAO:C',
        edit: 'REPORT:RP:DTRAO:U',
        delete: 'REPORT:RP:DTRAO:D',
        all: ['REPORT:RP:DTRAO:R', 'REPORT:RP:DTRAO:C', 'REPORT:RP:DTRAO:U', 'REPORT:RP:DTRAO:D']
    },
    REP_OB_RING_CLAIM_CUST_MONTHLY: {
        code: 'REPORT:RP:MTRAO',
        read: 'REPORT:RP:MTRAO:R',
        create: 'REPORT:RP:MTRAO:C',
        edit: 'REPORT:RP:MTRAO:U',
        delete: 'REPORT:RP:MTRAO:D',
        all: ['REPORT:RP:MTRAO:R', 'REPORT:RP:MTRAO:C', 'REPORT:RP:MTRAO:U', 'REPORT:RP:MTRAO:D']
    },
    REP_OB_RING_CLAIM_DEALER: {
        code: 'REPORT:RP:TRAD',
        read: 'REPORT:RP:TRAD:R',
        create: 'REPORT:RP:TRAD:C',
        edit: 'REPORT:RP:TRAD:U',
        delete: 'REPORT:RP:TRAD:D',
        all: ['REPORT:RP:TRAD:R', 'REPORT:RP:TRAD:C', 'REPORT:RP:TRAD:U', 'REPORT:RP:TRAD:D']
    },
    REP_OB_RING_REMAIN: {
        code: 'REPORT:RP:TRARE',
        read: 'REPORT:RP:TRARE:R',
        create: 'REPORT:RP:TRARE:C',
        edit: 'REPORT:RP:TRARE:U',
        delete: 'REPORT:RP:TRARE:D',
        all: ['REPORT:RP:TRARE:R', 'REPORT:RP:TRARE:C', 'REPORT:RP:TRARE:U', 'REPORT:RP:TRARE:D']
    },
    REP_OB_RING_SO: {
        code: 'REPORT:RP:SO',
        read: 'REPORT:RP:SO:R',
        create: 'REPORT:RP:SO:C',
        edit: 'REPORT:RP:SO:U',
        delete: 'REPORT:RP:SO:D',
        all: ['REPORT:RP:SO:R', 'REPORT:RP:SO:C', 'REPORT:RP:SO:U', 'REPORT:RP:SO:D']
    },
    REP_OB_RING_INV: {
        code: 'REPORT:RP:INV',
        read: 'REPORT:RP:INV:R',
        create: 'REPORT:RP:INV:C',
        edit: 'REPORT:RP:INV:U',
        delete: 'REPORT:RP:INV:D',
        all: ['REPORT:RP:INV:R', 'REPORT:RP:INV:C', 'REPORT:RP:INV:U', 'REPORT:RP:INV:D']
    },
    /*==================== Gratis Claim ====================*/
    REP_OB_GRATIS_PROMO_CATEGORY: {
        code: 'REPORT:GT:PROCAT',
        read: 'REPORT:GT:PROCAT:R',
        create: 'REPORT:GT:PROCAT:C',
        edit: 'REPORT:GT:PROCAT:U',
        delete: 'REPORT:GT:PROCAT:D',
        all: ['REPORT:GT:PROCAT:R', 'REPORT:GT:PROCAT:C', 'REPORT:GT:PROCAT:U', 'REPORT:GT:PROCAT:D']
    },
    REP_OB_GRATIS_REPORT_SUMMARY: {
        code: 'REPORT:GT:SGT',
        read: 'REPORT:GT:SGT:R',
        create: 'REPORT:GT:SGT:C',
        edit: 'REPORT:GT:SGT:U',
        delete: 'REPORT:GT:SGT:D',
        all: ['REPORT:GT:SGT:R', 'REPORT:GT:SGT:C', 'REPORT:GT:SGT:U', 'REPORT:GT:SGT:D']
    },
    REP_OB_GRATIS_ALL_DETAIL: {
        code: 'REPORT:GT:ALL',
        read: 'REPORT:GT:ALL:R',
        create: 'REPORT:GT:ALL:C',
        edit: 'REPORT:GT:ALL:U',
        delete: 'REPORT:GT:ALL:D',
        all: ['REPORT:GT:ALL:R', 'REPORT:GT:ALL:C', 'REPORT:GT:ALL:U', 'REPORT:GT:ALL:D']
    },
    REP_OB_GRATIS_REPORT_DETAIL: {
        code: 'REPORT:GT:DGT',
        read: 'REPORT:GT:DGT:R',
        create: 'REPORT:GT:DGT:C',
        edit: 'REPORT:GT:DGT:U',
        delete: 'REPORT:GT:DGT:D',
        all: ['REPORT:GT:DGT:R', 'REPORT:GT:DGT:C', 'REPORT:GT:DGT:U', 'REPORT:GT:DGT:D']
    },
    REP_OB_GRATIS_MANUAL: {
        code: 'REPORT:GT:MANUAL',
        read: 'REPORT:GT:MANUAL:R',
        create: 'REPORT:GT:MANUAL:C',
        edit: 'REPORT:GT:MANUAL:U',
        delete: 'REPORT:GT:MANUAL:D',
        all: ['REPORT:GT:MANUAL:R', 'REPORT:GT:MANUAL:C', 'REPORT:GT:MANUAL:U', 'REPORT:GT:MANUAL:D']
    },
    REP_OB_OUTLET_INCENTIVE_CLAIM: {
        code: 'REPORT:GT:ICT',
        read: 'REPORT:GT:ICT:R',
        create: 'REPORT:GT:ICT:C',
        edit: 'REPORT:GT:ICT:U',
        delete: 'REPORT:GT:ICT:D',
        all: ['REPORT:GT:ICT:R', 'REPORT:GT:ICT:C', 'REPORT:GT:ICT:U', 'REPORT:GT:ICT:D']
    },
    /*==================== Downline ====================*/
    REP_OB_DOWNLINE: {
        code: 'REPORT:DWL:DWL',
        read: 'REPORT:DWL:DWL:R',
        create: 'REPORT:DWL:DWL:C',
        edit: 'REPORT:DWL:DWL:U',
        delete: 'REPORT:DWL:DWL:D',
        all: ['REPORT:DWL:DWL:R', 'REPORT:DWL:DWL:C', 'REPORT:DWL:DWL:U', 'REPORT:DWL:DWL:D']
    },
    REP_OB_CALL_PLAN: {
        code: 'REPORT:DWL:CALL',
        read: 'REPORT:DWL:CALL:R',
        create: 'REPORT:DWL:CALL:C',
        edit: 'REPORT:DWL:CALL:U',
        delete: 'REPORT:DWL:CALL:D',
        all: ['REPORT:DWL:CALL:R', 'REPORT:DWL:CALL:C', 'REPORT:DWL:CALL:U', 'REPORT:DWL:CALL:D']
    },
    REP_OB_AUDIT_PLAN: {
        code: 'REPORT:DWL:AUDIT',
        read: 'REPORT:DWL:AUDIT:R',
        create: 'REPORT:DWL:AUDIT:C',
        edit: 'REPORT:DWL:AUDIT:U',
        delete: 'REPORT:DWL:AUDIT:D',
        all: ['REPORT:DWL:AUDIT:R', 'REPORT:DWL:AUDIT:C', 'REPORT:DWL:AUDIT:U', 'REPORT:DWL:AUDIT:D']
    },
    REP_OB_ATTENDANT: {
        code: 'REPORT:DWL:ATD',
        read: 'REPORT:DWL:ATD:R',
        create: 'REPORT:DWL:ATD:C',
        edit: 'REPORT:DWL:ATD:U',
        delete: 'REPORT:DWL:ATD:D',
        all: ['REPORT:DWL:ATD:R', 'REPORT:DWL:ATD:C', 'REPORT:DWL:ATD:U', 'REPORT:DWL:ATD:D']
    },
    /*==================== Outlet ====================*/
    REP_OB_OUTLET: {
        code: 'REPORT:OL:OUTLET',
        read: 'REPORT:OL:OUTLET:R',
        create: 'REPORT:OL:OUTLET:C',
        edit: 'REPORT:OL:OUTLET:U',
        delete: 'REPORT:OL:OUTLET:D',
        all: ['REPORT:OL:OUTLET:R', 'REPORT:OL:OUTLET:C', 'REPORT:OL:OUTLET:U', 'REPORT:OL:OUTLET:D']
    },
    /*==================== Empty Keg/Bottle ====================*/
    REP_OB_EMPTY: {
        code: 'REPORT:KEG:KEG',
        read: 'REPORT:KEG:KEG:R',
        create: 'REPORT:KEG:KEG:C',
        edit: 'REPORT:KEG:KEG:U',
        delete: 'REPORT:KEG:KEG:D',
        all: ['REPORT:KEG:KEG:R', 'REPORT:KEG:KEG:C', 'REPORT:KEG:KEG:U', 'REPORT:KEG:KEG:D']
    },
    REP_OB_EMPTY_RETURN_OUTLET: {
        code: 'REPORT:KEG:OUTLET',
        read: 'REPORT:KEG:OUTLET:R',
        create: 'REPORT:KEG:OUTLET:C',
        edit: 'REPORT:KEG:OUTLET:U',
        delete: 'REPORT:KEG:OUTLET:D',
        all: ['REPORT:KEG:OUTLET:R', 'REPORT:KEG:OUTLET:C', 'REPORT:KEG:OUTLET:U', 'REPORT:KEG:OUTLET:D']
    },
    /*==================== Pallet ====================*/
    REP_OB_PALLET: {
        code: 'REPORT:PLT:PLT',
        read: 'REPORT:PLT:PLT:R',
        create: 'REPORT:PLT:PLT:C',
        edit: 'REPORT:PLT:PLT:U',
        delete: 'REPORT:PLT:PLT:D',
        all: ['REPORT:PLT:PLT:R', 'REPORT:PLT:PLT:C', 'REPORT:PLT:PLT:U', 'REPORT:PLT:PLT:D']
    },

    /*==================== BA/SA ====================*/
    REP_BA_MASTER: {
        code: 'REPORT:BA:MASTER',
        read: 'REPORT:BA:MASTER:R',
        create: 'REPORT:BA:MASTER:C',
        edit: 'REPORT:BA:MASTER:U',
        delete: 'REPORT:BA:MASTER:D',
        all: ['REPORT:BA:MASTER:R', 'REPORT:BA:MASTER:C', 'REPORT:BA:MASTER:U', 'REPORT:BA:MASTER:D']
    },
    REP_BA_RQ_PEDING: {
        code: 'REPORT:BA:REQUEST',
        read: 'REPORT:BA:REQUEST:R',
        create: 'REPORT:BA:REQUEST:C',
        edit: 'REPORT:BA:REQUEST:U',
        delete: 'REPORT:BA:REQUEST:D',
        all: ['REPORT:BA:REQUEST:R', 'REPORT:BA:REQUEST:C', 'REPORT:BA:REQUEST:U', 'REPORT:BA:REQUEST:D']
    },
    REP_BA_RQ_DEPLOY: {
        code: 'REPORT:BA:DEPLOY',
        read: 'REPORT:BA:DEPLOY:R',
        create: 'REPORT:BA:DEPLOY:C',
        edit: 'REPORT:BA:DEPLOY:U',
        delete: 'REPORT:BA:DEPLOY:D',
        all: ['REPORT:BA:DEPLOY:R', 'REPORT:BA:DEPLOY:C', 'REPORT:BA:DEPLOY:U', 'REPORT:BA:DEPLOY:D']
    },
    REP_BA_RETURN: {
        code: 'REPORT:BA:RETURN',
        read: 'REPORT:BA:RETURN:R',
        create: 'REPORT:BA:RETURN:C',
        edit: 'REPORT:BA:RETURN:U',
        delete: 'REPORT:BA:RETURN:D',
        all: ['REPORT:BA:RETURN:R', 'REPORT:BA:RETURN:C', 'REPORT:BA:RETURN:U', 'REPORT:BA:RETURN:D']
    },
    REP_BA_MOVEMENT: {
        code: 'REPORT:BA:MOVEMENT',
        read: 'REPORT:BA:MOVEMENT:R',
        create: 'REPORT:BA:MOVEMENT:C',
        edit: 'REPORT:BA:MOVEMENT:U',
        delete: 'REPORT:BA:MOVEMENT:D',
        all: ['REPORT:BA:MOVEMENT:R', 'REPORT:BA:MOVEMENT:C', 'REPORT:BA:MOVEMENT:U', 'REPORT:BA:MOVEMENT:D']
    },

    /*==================== User Report ====================*/
    /*==================== User Login Report ====================*/
    REP_US_LOGIN: {
        code: 'REPORT:USER:LOGIN',
        read: 'REPORT:USER:LOGIN:R',
        create: 'REPORT:USER:LOGIN:C',
        edit: 'REPORT:USER:LOGIN:U',
        delete: 'REPORT:USER:LOGIN:D',
        all: ['REPORT:USER:LOGIN:R', 'REPORT:USER:LOGIN:C', 'REPORT:USER:LOGIN:U', 'REPORT:USER:LOGIN:D']
    }
};
