import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    url = environment.apiUrl;
    // apiRingPull = environment.apiRingPull;
    reportUrl = environment.apiReportUrl;
    authorization = environment.authorization;
    clientId = environment.clientId;

    constructor() {}

    getHeader() {
        return new HttpHeaders({
            'Content-type': 'application/json',
            Authorization: localStorage.getItem('token') || '',
            'x-platform': 'WEB',
            'x-udid': localStorage.getItem('udid')
        });
    }
    getHeaderRingPull() {
        return new HttpHeaders({
            'Content-type': 'application/json-patch+json',
            accept: '*/*',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
    }

    /**
     *  Delete unecessary and unwanted fields from paramter obj.
     */
    cleanParams(obj: Object, deletedFieldNames: string[] = [], allowedZero: boolean = false) {
        if (obj) {
            Object.keys(obj).forEach((o) => {
                if (
                    (((allowedZero && obj[o] !== 0 && !obj[o]) || (!allowedZero && !obj[o])) && obj[o] !== false) ||
                    (this.isArray(obj[o]) && !obj[o].length) ||
                    (deletedFieldNames && deletedFieldNames.length && deletedFieldNames.includes(o))
                )
                    delete obj[o];
            });
            return obj as HttpParams;
        }
        return null;
    }

    isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    }

    async getUDID() {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        localStorage.setItem('udid', result.visitorId);
    }
}
