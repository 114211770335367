import { PermissionModule } from 'src/app/shared/models/role-permission';
import { environment } from 'src/environments/environment';
import { MENU_PERMISSION_CODE as CODE } from '../../navigation/menu-permission-code';

const env = environment.env;

export const REPORT_PERMISSION_MODULE: PermissionModule[] = [
           /*==================================================
    REPORT
    ==================================================*/
           {
               id: 'ALL-REPORT-LV2',
               name: 'Report',
               code: '',
               classes: '',
               isCheck: false,
               visible: true,
               subModules: [
                   {
                       id: 'REPORT-LV2-INBOUND',
                       name: 'Inbound Order',
                       code: '',
                       classes: '',
                       isCheck: false,
                       visible: true,
                       subModules: [
                           /*==================================================
                    REP IB (PURCHASE ORDER)
                    ==================================================*/
                           {
                               id: 'REP-IB-LV3-PURCHASE-ORDER',
                               name: 'Purchase Order',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-IB-PURCHASE-REMAINING',
                                       name: 'Purchase Order Remaining',
                                       code: CODE.REP_IB_PURCHASE_REMAINING.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-PURCHASE-ORDER',
                                       name: 'Purchase Order List',
                                       code: CODE.REP_IB_PURCHASE_ORDER.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-PURCHASE-DELIVERY',
                                       name: 'Purchase Delivery Transaction',
                                       code: CODE.REP_IB_PURCHASE_DELIVERY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-CUST-PAYMENT',
                                       name: 'Customer Payment Report',
                                       code: CODE.REP_IB_CUST_PAYMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP IB (RING PULL)
                    ==================================================*/
                           {
                               id: 'REP-IB-LV3-RING-PULL',
                               name: 'Ring Pull Claim',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-IB-RING-REMAINING',
                                       name: 'Ring Pull Order Remaining',
                                       code: CODE.REP_IB_RING_REMAINING.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-RING-PULL',
                                       name: 'Ring Pull List',
                                       code: CODE.REP_IB_RING_PULL.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-RING-DELIVERY',
                                       name: 'Ring Pull Delivery Transaction',
                                       code: CODE.REP_IB_RING_DELIVERY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP IB (GRATIS)
                    ==================================================*/
                           {
                               id: 'REP-IB-LV3-GRATIS',
                               name: 'Gratis',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-IB-GRATIS-REMAINING',
                                       name: 'Gratis Order Remaining',
                                       code: CODE.REP_IB_GRATIS_REMAINING.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-GRATIS-CLAIM',
                                       name: 'Gratis Order List',
                                       code: CODE.REP_IB_GRATIS_CLAIM.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-GRATIS-DELIVERY',
                                       name: 'Gratis Delivery Transaction',
                                       code: CODE.REP_IB_GRATIS_DELIVERY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP IB (SALES REPORT)
                    ==================================================*/
                           {
                               id: 'REP-IB-LV3-SALES-REPORT',
                               name: 'Sales Report',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-IB-SALES-REPORT',
                                       name: 'Inbound Sales Monthly Report',
                                       code: CODE.REP_IB_SALES_REPORT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-SALES-SUMMARY',
                                       name: 'Inbound Target Vs Actual Report',
                                       code: CODE.REP_IB_SALES_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-DEALER-PURCHASE',
                                       name: 'Dealer Purchase Order Performance Report',
                                       code: CODE.REP_IB_DEALER_PURCHASE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP IB (RETURN REPORT)
                    ==================================================*/
                           {
                               id: 'REP-IB-LV3-RETURN',
                               name: 'Return',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-IB-RETURN-PO',
                                       name: 'PO Return Report',
                                       code: CODE.REP_IB_RETURN_PO.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-IB-RETURN-DO',
                                       name: 'DO Return Report',
                                       code: CODE.REP_IB_RETURN_DO.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           }
                       ]
                   },
                   {
                       id: 'REPORT-LV2-OUTBOUND',
                       name: 'Outbound Order',
                       code: '',
                       classes: '',
                       isCheck: false,
                       visible: true,
                       subModules: [
                           /*==================================================
                    REP OB (SALES REPORT)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-SALES-REPORT',
                               name: 'Sales Report',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-SALES-REPORT',
                                       name: 'Daily Shipment Plan Outbound',
                                       code: CODE.REP_OB_DAILY_SHIP_PLAN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SALE-DAILY-STS',
                                       name: 'Sales Daily Report By Status',
                                       code: CODE.REP_OB_SALE_DAILY_STATUS.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   // {
                                   //     id: 'REP-OB-SALE-DAILY-STSD',
                                   //     name: 'Sales Daily Report By Status Detail',
                                   //     code: CODE.REP_OB_SALE_DAILY_STATUS_DETAIL.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   {
                                       id: 'REP-OB-SALES-TRANS-SUMM',
                                       name: 'Outbound Sales Monthly Report',
                                       code: CODE.REP_OB_SALE_TRANSACTION.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SALES-SUMM-REP',
                                       name: 'Outbound Target Vs Actual Report',
                                       code: CODE.REP_OB_SALE_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-PRODUCT-RETURN',
                                       name: 'Product Return Report',
                                       code: CODE.REP_OB_PROD_RETURN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-PRODUCT-ROTATION',
                                       name: 'Product Rotation Report',
                                       code: CODE.REP_OB_PROD_ROTATION.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SO-REMAINING',
                                       name: 'Outbound Sales Order Remaining',
                                       code: CODE.REP_OB_SO_REMAINING.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (KPI & PERFORMANCE)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-KPI-PERFOMANCE',
                               name: 'KPI and Performance',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-CALL-COMPL-EFFECT',
                                       name: 'Call Compliance & Effectiveness Report',
                                       code: CODE.REP_OB_CALL_COMPLIANCE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'SS-REP-OB-CALL-COMPL-EFFECT',
                                       name: 'SS Call Compliance & Effectiveness Report',
                                       code: CODE.REP_OB_SS_CALL_COMPLIANCE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-PERF',
                                       name: 'Outlet Performance Summary',
                                       code: CODE.REP_OB_OUTLET_PERF_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-PERF-SUMMARY-DO',
                                       name: 'Outlet Performance Summary By Delivery',
                                       code: CODE.REP_OB_OUTLET_PERF_SUMMARY_DO.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-PERF-SUMMARY-DO-NEW',
                                       name: 'Outlet Performance Summary By Delivery New',
                                       code: CODE.REP_OB_OUTLET_PERF_SUMMARY_DO_NEW.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   // {
                                   //     id: 'REP-OB-MONTH-SALE-DEALER-CATEG',
                                   //     name: 'Monthly Sales Performance By Dealer By Category',
                                   //     code: CODE.REP_OB_MONTHLY_SALE_DEALER_CATEGORY.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   {
                                       id: 'REP-OB-MONTH-SALE-DEALER-PROD',
                                       name: 'Monthly Sales Performance By Dealer By Product',
                                       code: CODE.REP_OB_MONTHLY_SALE_DEALER_PROD.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-PROD-AVAIL-OUTLET',
                                       name: 'Product Availability And Freshness By Outlet Report',
                                       code: CODE.REP_OB_PROD_AVAIL_OUTLET.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-PROD-AVAIL-SUMM',
                                       name: 'Product Availability Summary Report',
                                       code: CODE.REP_OB_PROD_AVAIL_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-NEW-OPEN-OUTLET',
                                       name: 'New Opening Outlet',
                                       code: CODE.REP_OB_NEW_OUTLET.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SURVEY-TRANSACTION',
                                       name: 'Survey Transaction',
                                       code: CODE.REP_OB_SURVEY_TRANSACTION.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-VISIT-SUMM',
                                       name: 'Outlet Visit Summary Report',
                                       code: CODE.REP_OB_OUTLET_VISIT_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SALE-OFFIER-PERF',
                                       name: 'Sales Officer Sales Performance Summary Report',
                                       code: CODE.REP_OB_SALE_PERF_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-PARTNER-INCENTIVE',
                                       name: 'Partnership Incentive Report',
                                       code: CODE.REP_OB_PARTNER_INCENTIVE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },

                           /*==================================================
                    REP OB (KPI & PERFORMANCE)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-PROMOTION-AGREEMENT',
                               name: 'Promotion and Agreement',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-PROMOTION',
                                       name: 'Promotion Report',
                                       code: CODE.REP_OB_PROMOTION.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-WAP-AGREEMENT',
                                       name: 'WPA Agreement Report',
                                       code: CODE.REP_OB_WAP_AGREEMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-AGREEMENT',
                                       name: 'Outlet Agreement Report',
                                       code: CODE.REP_OB_OUTLET_AGREEMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },

                           /*==================================================
                    REP OB (AR/AP)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-AR-AP',
                               name: 'AR/AP',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-CUST-PAYMENT',
                                       name: 'Customer Payment Report',
                                       code: CODE.REP_OB_CUST_PAYMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-DAILY-SALE-INV-PAY',
                                       name: 'Daily Sales Invoice And Payment Report',
                                       code: CODE.REP_OB_DAILY_SALE_INV_PAY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-DETAIL-SALE-INV-INFO',
                                       name: 'Details Of Invoice Information Report',
                                       code: CODE.REP_OB_DETAIL_INV_INFO.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (TRADE MARKETING)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-TRADE-MARKET',
                               name: 'Trade Marketing',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   /*===== Don't remove this, BEFORE ENHANCEMENT =====*/
                                   // {
                                   //     id: 'REP-OB-ASSET-DETAIL-OUTLET',
                                   //     name: 'Trade Asset Deployment Detail Report',
                                   //     code: CODE.REP_OB_ASSET_DETAIL_OUTLET.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: env === 'staging',
                                   // },
                                   // {
                                   //     id: 'REP-OB-FRIDGE-DEPLOY-RETURN',
                                   //     name: 'Trade Asset Return Report',
                                   //     code: CODE.REP_OB_FRIDGE_DEPLOY_RETURN.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: env === 'staging',
                                   // },
                                   /*===== Don't remove this, FUTURE ENHANCEMENT =====*/
                                   {
                                       id: 'REP-OB-TAS-MASTER',
                                       name: 'Trade Asset Master Report',
                                       code: CODE.REP_OB_TAS_MASTER.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-TAS-DEPLOY',
                                       name: 'Trade Asset Request & Deploy Report',
                                       code: CODE.REP_OB_TAS_DEPLOY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-TAS-RETURN',
                                       name: 'Trade Asset Return Report',
                                       code: CODE.REP_OB_TAS_RETURN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-TAS-MOVEMENT',
                                       name: 'Trade Asset Movement History Report',
                                       code: CODE.REP_OB_TAS_MOVEMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   /*=================================================*/
                                   {
                                       id: 'REP-OB-ASSET-DETAIL-DEALER',
                                       name: 'Trade Asset Movement',
                                       code: CODE.REP_OB_ASSET_DETAIL_DEALER.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SIGN-REQUEST-DEPLOY',
                                       name: 'Signage Request And Deployment Report',
                                       code: CODE.REP_OB_SIGN_REQUEST_DEPLOY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                                   // {
                                   //     id: 'REP-OB-OUTLET-PROMO-TRANSACTION',
                                   //     name: 'Outlet Promotion Transaction Report',
                                   //     code: CODE.REP_OB_OUTLET_PROMO_TRANSACTION.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: false,
                                   // },
                                   // {
                                   //     id: 'REP-OB-OUTLET-PROMO-TRANSACTION',
                                   //     name: 'Outlet Promotion Transaction Report',
                                   //     code: CODE.REP_OB_OUTLET_PROMO_TRANSACTION.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   // {
                                   //     id: 'REP-OB-MARKET-PROMO-DETAIL',
                                   //     name: 'Trade Marketing Promotion Detail Report',
                                   //     code: CODE.REP_OB_MARKET_PROMO_DETAIL.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   // {
                                   //     id: 'REP-OB-MARKET-PROMO-SUMMARY',
                                   //     name: 'Trade Marketing Promotion Summary Report',
                                   //     code: CODE.REP_OB_MARKET_PROMO_SUMMARY.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                               ]
                           },
                           /*==================================================
                    REP OB (POSM)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-TOUCH-POINT',
                               name: 'POSM',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-POSM-REQUEST',
                                       name: 'POSM Request',
                                       code: CODE.REP_OB_POSM_REQUEST.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-POSM-DEPLOY',
                                       name: 'POSM Deployment',
                                       code: CODE.REP_OB_POSM_DEPLOY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-POSM-MOVEMENT',
                                       name: 'POSM Movement',
                                       code: CODE.REP_OB_POSM_MOVEMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (INVENTORY)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-INVENTORY',
                               name: 'Inventory',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-INVENTORY-MOVE-SUMMARY',
                                       name: 'Inventory Movement Summary Report',
                                       code: CODE.REP_OB_INVENTORY_MOVE_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-STOCK-MOVE-DETAIL',
                                       name: 'Inventory Transaction Report',
                                       code: CODE.REP_OB_STOCK_MOVE_DETAIL.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   // {
                                   //     id: 'REP-OB-INV-MOVE-DETAIL',
                                   //     name: 'Inventory Movement Details Report',
                                   //     code: CODE.REP_OB_INV_MOVE_DETAIL.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   // {
                                   //     id: 'REP-OB-DAILY-INV-MOVE-DETAIL',
                                   //     name: 'Daily Inventory Movement Details Report',
                                   //     code: CODE.REP_OB_DAILY_INV_MOVE_DETAIL.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   {
                                       id: 'REP-OB-INV-MOVE-ADJ',
                                       name: 'Inventory Movement With Adjustment Report',
                                       code: CODE.REP_OB_INV_MOVE_ADJ.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   // {
                                   //     id: 'REP-OB-INV-MOVE-ADJ-DAILY',
                                   //     name: 'Daily Inventory Movement With Adjustment Report',
                                   //     code: CODE.REP_OB_INV_MOVE_ADJ_DAILY.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   // {
                                   //     id: 'REP-OB-INV-SALE-MOVE',
                                   //     name: 'Inventory & Sales Volume Movement Report',
                                   //     code: CODE.REP_OB_INV_SALE_MOVE.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   {
                                       id: 'REP-OB-INV-SALE-MOVE-DAILY',
                                       name: 'Daily Inventory & Sales Volume Movement Report',
                                       code: CODE.REP_OB_INV_SALE_MOVE_DAILY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-INV-ON-HAND',
                                       name: 'Inventory On Hand Report',
                                       code: CODE.REP_OB_INV_ON_HAND.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-SAFE-STOCK',
                                       name: 'Safe Stock Report',
                                       code: CODE.REP_OB_SAFE_STOCK.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-STOCK-RECONCILE',
                                       name: 'Stock Reconcile Report',
                                       code: CODE.REP_OB_STOCK_RECONCILE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (RING PULL CLAIM)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-RING-PULL',
                               name: 'Ring Pull Claim',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-RING-TRANSACTION',
                                       name: 'Ring Pull Delivery Report',
                                       code: CODE.REP_OB_RING_TRANSACTION.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-CLAIM-CUST-DAILY',
                                       name: 'Ring Pull Claim Report By Customer Daily',
                                       code: CODE.REP_OB_RING_CLAIM_CUST_DAILY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-CLAIM-CUST-MONTHLY',
                                       name: 'Ring Pull Claim Report By Customer Monthly',
                                       code: CODE.REP_OB_RING_CLAIM_CUST_MONTHLY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-CLAIM-DEALER',
                                       name: 'Ring Pull Claim Report By Dealer',
                                       code: CODE.REP_OB_RING_CLAIM_DEALER.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-REMAIN',
                                       name: 'Ring Pull Remaining',
                                       code: CODE.REP_OB_RING_REMAIN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-SO',
                                       name: 'Ring Pull Sales Order',
                                       code: CODE.REP_OB_RING_SO.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-RING-INV',
                                       name: 'Ring Pull Claim Inventory Transaction Report',
                                       code: CODE.REP_OB_RING_INV.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (GRATIS CLAIM)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-GRATIS-CLAIM',
                               name: 'Gratis Claim',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   // {
                                   //     id: 'REP-OB-GRATIS-PROMO-CATEGORY',
                                   //     name: 'Gratis Promotion Category',
                                   //     code: CODE.REP_OB_GRATIS_PROMO_CATEGORY.read,
                                   //     classes: 'chb-view',
                                   //     isCheck: false,
                                   //     visible: true,
                                   // },
                                   {
                                       id: 'REP-OB-GRATIS-REPORT-SUMMARY',
                                       name: 'Gratis Report Summary',
                                       code: CODE.REP_OB_GRATIS_REPORT_SUMMARY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-GRATIS-ALL-DETAIL',
                                       name: 'All Gratis Claim Details Report',
                                       code: CODE.REP_OB_GRATIS_ALL_DETAIL.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-GRATIS-REPORT-DETAIL',
                                       name: 'Trade Promotion Claim Details',
                                       code: CODE.REP_OB_GRATIS_REPORT_DETAIL.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-GRATIS-MANUAL',
                                       name: 'Manual Claim Details',
                                       code: CODE.REP_OB_GRATIS_MANUAL.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-OUTLET-INCENTIVE-CLAIM',
                                       name: 'Outlet Incentive Claim Details',
                                       code: CODE.REP_OB_OUTLET_INCENTIVE_CLAIM.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (DOWNLINE)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-DOWNLINE',
                               name: 'Downline',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-DOWNLINE',
                                       name: 'Downline Report',
                                       code: CODE.REP_OB_DOWNLINE.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-CALL-PLAN',
                                       name: 'Call Plan Report',
                                       code: CODE.REP_OB_CALL_PLAN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-AUDIT-PLAN',
                                       name: 'Audit Plan Report',
                                       code: CODE.REP_OB_AUDIT_PLAN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   /* ===== Don't remove this, FUTURE ENHANCEMENT ===== */
                                   {
                                       id: 'REP-OB-ATTENDANT',
                                       name: 'Attendant Report',
                                       code: CODE.REP_OB_ATTENDANT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                                   /* ================================================= */
                               ]
                           },
                           /*==================================================
                    REP OB (OUTLET)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-OUTLET',
                               name: 'Outlet',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-OUTLET',
                                       name: 'Outlet Report',
                                       code: CODE.REP_OB_OUTLET.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (EMPTY KEG/BOTTLE)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-EMPTY-KEG',
                               name: 'Empty Keg/Bottle',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-EMPTY',
                                       name: 'Empty Keg/Bottle Report',
                                       code: CODE.REP_OB_EMPTY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-OB-EMPTY-RETURN-OUTLET',
                                       name: 'Empty Keg/Bottle Return By Outlet Report',
                                       code: CODE.REP_OB_EMPTY_RETURN_OUTLET.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (PALLET)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-PALLET',
                               name: 'Pallet',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-OB-PALLET',
                                       name: 'Pallet Report',
                                       code: CODE.REP_OB_PALLET.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           },
                           /*==================================================
                    REP OB (BA/SA)
                    ==================================================*/
                           {
                               id: 'REP-OB-LV3-BA-SA',
                               name: 'BA/SA',
                               code: '',
                               classes: '',
                               isCheck: false,
                               visible: true,
                               subModules: [
                                   {
                                       id: 'REP-BA-MASTER',
                                       name: 'BA/SA Master',
                                       code: CODE.REP_BA_MASTER.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-BA-RQ-PEDING',
                                       name: 'BA/SA Request',
                                       code: CODE.REP_BA_RQ_PEDING.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-BA-RQ-DEPLOY',
                                       name: 'BA/SA Deploy',
                                       code: CODE.REP_BA_RQ_DEPLOY.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-BA-RETURN',
                                       name: 'BA/SA Return',
                                       code: CODE.REP_BA_RETURN.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   },
                                   {
                                       id: 'REP-BA-MOVEMENT',
                                       name: 'BA/SA Movement',
                                       code: CODE.REP_BA_MOVEMENT.read,
                                       classes: 'chb-view',
                                       isCheck: false,
                                       visible: true
                                   }
                               ]
                           }
                       ]
                   },
                   {
                       id: 'REPORT-LV2-USER',
                       name: 'System User',
                       code: '',
                       classes: '',
                       isCheck: false,
                       visible: true,
                       subModules: [
                           /*==================================================
                    REP OB (USER LOGIN REPORT)
                    ==================================================*/
                           {
                               id: 'REP-US-LOGIN',
                               name: 'User Login Report',
                               code: CODE.REP_US_LOGIN.read,
                               classes: 'chb-view',
                               isCheck: false,
                               visible: true
                           }
                       ]
                   }
               ]
           }
       ];
